import React, { useId } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { useFormGroup } from '@tripledotstudios/react-core';
import { useFormPermissions } from '@hooks';
import Label from './Label';
import Error from './Error';

const Switch = ({
  name, className, inlineLabel, labelOptions = {}, onChange, disabled, id, onBlur, ...rest
}) => {
  const inputId = id || useId();
  const { register } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();

  return (
    <div className={classnames('form-check', 'form-switch', 'mt-2', className)}>
      <input
        type="checkbox"
        className="form-check-input cursor-pointer"
        id={inputId}
        {...register(fullName, { onChange, onBlur })}
        {...rest}
        disabled={disabled || readOnly}
      />
      {inlineLabel && (
        <Label
          label={inlineLabel}
          className="form-check-label pt-0 cursor-pointer user-select-none"
          htmlFor={inputId}
          {...labelOptions}
        />
      )}
      <Error name={name} />
    </div>
  );
};

const LabeledSwitch = ({
  label, name, sizes, tooltipText, flatten = false, ...rest
}) => {
  const inputId = useId();

  return flatten ? (
    <>
      <Label
        htmlFor={inputId}
        label={label}
        sizes={sizes}
        tooltipText={tooltipText}
        className="cursor-pointer user-select-none"
      />
      <Switch name={name} id={inputId} {...rest} />
    </>
  ) : (
    <Label
      htmlFor={inputId}
      label={label}
      sizes={sizes}
      tooltipText={tooltipText}
      className="cursor-pointer user-select-none"
    >
      <Switch name={name} id={inputId} {...rest} />
    </Label>
  );
};

export { Switch, LabeledSwitch };

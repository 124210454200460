import React from 'react';
import { InputGroup, Row, Col } from 'react-bootstrap';
import { Tooltip } from '@tripledotstudios/react-core';

import { Switch, InputWithAddon } from '../resource';

export const FormulaConfig = ({ disabled }) => (
  <Switch
    name="roundEnabled"
    disabled={disabled}
    inlineLabel="Round to nearest integer"
    labelOptions={{ sizes: [12] }}
  />
);

export const FormulaInput = ({ name, disabled }) => (
  <Row>
    <Col>
      <InputWithAddon
        name={name}
        disabled={disabled}
        before={(
          <InputGroup.Text className="low-height-input">
            <Tooltip text="Formula allow you to use user attributes to calculate the parameter's value" placement="top">
              <div className="fs-4 fst-italic user-select-none">
                f
                <span className="fs-6">x</span>
              </div>
            </Tooltip>
          </InputGroup.Text>
        )}
      />
    </Col>
    <Col>
      <InputWithAddon
        disabled={disabled}
        before="Fallback"
        name="fallbackValue"
        type="number"
        step="0.00001"
      />
    </Col>
  </Row>
);

import { useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, InputGroup } from 'react-bootstrap';
import { useFormGroup } from '@tripledotstudios/react-core';

import { useFormPermissions } from '@hooks';
import Label from './Label';
import FloatingLabel from './FloatingLabel';
import Error from './Error';

const Input = ({
  name,
  type,
  onChange,
  disabled = false,
  ...rest
}) => {
  const registerOptions = {};
  const { register } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();
  if (type === 'number') registerOptions.valueAsNumber = true;
  const { onChange: onFieldChange, ...registeredProps } = register(fullName, registerOptions);

  return (
    <>
      <Form.Control
        {...registeredProps}
        onChange={(result) => {
          if (onChange) try { onChange(result); } catch (e) { console.error(e); }
          onFieldChange(result);
        }}
        type={type}
        disabled={disabled || readOnly}
        {...rest}
      />
      <Error name={name} />
    </>
  );
};

const InputWithAddon = ({
  before, after, name, groupOptions = {}, type, ...rest
}) => {
  const registerOptions = {};
  const { register } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();
  if (type === 'number') registerOptions.valueAsNumber = true;

  return (
    <>
      <InputGroup {...groupOptions}>
        {before && typeof before === 'string' ? (
          <InputGroup.Text className="user-select-none">{before}</InputGroup.Text>
        ) : before}
        <Form.Control
          {...register(fullName, registerOptions)}
          type={type}
          disabled={readOnly}
          {...rest}
        />
        {after && typeof after === 'string' ? (
          <InputGroup.Text className="user-select-none">{after}</InputGroup.Text>
        ) : after}
      </InputGroup>
      <Error name={name} />
    </>
  );
};

const LabeledInput = ({
  label,
  sizes,
  tooltipText,
  id: externalId,
  labelProps,
  ...rest
}) => {
  const internalId = useId();
  const id = externalId || internalId;

  return (
    <Label label={label} sizes={sizes} tooltipText={tooltipText} htmlFor={id} {...labelProps}>
      <Input id={id} {...rest} />
    </Label>
  );
};

const FloatingLabeledInput = ({
  label, sizes: _sizes, tooltipText, ...rest
}) => (
  <FloatingLabel label={label} tooltipText={tooltipText}>
    <Input {...rest} />
  </FloatingLabel>
);

export {
  Input, LabeledInput, FloatingLabeledInput, InputWithAddon,
};

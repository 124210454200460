import { useMemo } from 'react';
import { InputGroup } from 'react-bootstrap';

import { Form, InUse } from '@components';
import { useRouter, PageSection } from '@tripledotstudios/react-core';

import {
  FloatingLabeledInput,
  LabeledInput,
  Label,
  InputWithAddon,
} from '@components/resource';

import { PaymentsRoutes } from '@pages/routes';
import { useAppData, useQuery } from '@hooks';
import { toOptions } from '@services/utils';
import Bots from './form/Bots';
import Ranks from './form/Ranks';
import DynamicParametersWithTemplate from '@/components/events/DynamicParametersWithTemplate';

export default function RaceForm({ resource, ...rest }) {
  const { query } = useRouter();
  const templateTypes = useAppData().enums['Events::ActivityTemplateTypes'];

  const { response: gameItems } = useQuery(
    PaymentsRoutes.GameItems.indexRequest, { ...query, withoutPagination: true, deleted: false },
  );

  const gameItemsOptions = useMemo(() => toOptions(
    gameItems?.items || [],
    {
      transform: (option, { backupGameItemsData }) => {
        // eslint-disable-next-line no-param-reassign
        option.backupGameItemsData = backupGameItemsData;

        return option;
      },
    },
  ), [gameItems?.items?.length]);

  if (!gameItems?.items) {
    return null;
  }

  const patchedResponse = {
    ...resource,
    data: {
      ...resource.data,
      activityTemplateId: resource.data.activityTemplateId || '',
    },
  };

  return (
    <Form.Resource resource={patchedResponse} {...rest}>
      <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
        <FloatingLabeledInput label="Name" name="name" />
        <InUse.AvailabilityStateFormGroup floating />
      </Form.ControlsLayout>
      <PageSection title="Race configuration">
        <Label
          label="Player speed reference"
          tooltipText="Player speed value representing player's speed to reach single target.
                      Supports math formulas and “avg_win_time_20_lvl” attribute"
        >
          <InputWithAddon
            name="playerSpeedRefFormula"
            before={(
              <InputGroup.Text className="fs-4 fst-italic">
                f
                <span className="fs-6">x</span>
              </InputGroup.Text>
            )}
          />
        </Label>
        <LabeledInput
          label="Fallback player speed reference"
          name="playerSpeedRefFallback"
          type="number"
          tooltipText="In case of missing data for Players speed reference,
                      this value will replace Players speed reference value"
        />
        <LabeledInput label="Target" name="target" type="number" />
        <DynamicParametersWithTemplate
          activityTemplateLabel="Race template"
          templateType={templateTypes.RACE}
          emptyTemplateOptionLabel="None"
          sizes={[4, 8]}
        />
      </PageSection>
      <Bots max={resource.data.maxBots} />
      <Ranks gameItemsOptions={gameItemsOptions} />
    </Form.Resource>
  );
}
